<template>
  <div class="loginBox" @click="setInputConfig">
    <div 
      class="leftBox" 
      v-if="loginBackground[bgIndex - 1]"
      :style="`
        background-image: url(${loginBackground[bgIndex - 1].image});
      `">
      <div class="text" :style="{
        bottom: loginBackground[bgIndex - 1].bottom,
        left: loginBackground[bgIndex - 1].left,
      }">
        <h1>Echola System</h1>
        <p>
          The logistics service platform is an online logistics public
          information service platform established by combining e-commerce 
          mode, Internet technology, logistics technology and other 
          information technologies.</p>
      </div>
    </div>
    <div class="rightBox" v-if="isAlive">
      <div class="center_box">
        <div v-show="loginTypeStatus == 'login'">
          <h2 id="loginTile">{{ $t('login.AccountLogin') }}</h2>
          <form @keyup.enter="submitForm('ruleForm')">
            <div class="inputBox">
              <div class="felxInput">
                <input
                  ref="username"
                  v-model.trim="ruleForm.username"
                  type="text"
                  id="inputName"
                  :maxlength="50"
                  v-focus
                  v-required="{
                    message: $t('login.Username'),
                    errorMessage: $t('login.enteroneusername')
                  }"
                />
              </div>
              <span>{{ $t("login.Username") }}</span>
            </div>
            <div class="inputBox">
              <div class="felxInput">
                <input
                  ref="password"
                  type="text"
                  v-if="isPasswordShow"
                  :maxlength="30"
                  v-focus
                  v-required="{
                    message: 'login.Password',
                    errorMessage: 'login.Pleaseinputapassword'
                  }"
                />
                <input
                  ref="password"
                  :type="passwordShow ? 'password' : 'text'"
                  v-if="!isPasswordShow"
                  :maxlength="30"
                  v-model="ruleForm.password"
                  v-focus
                  v-required="{
                    message: $t('login.Password'),
                    errorMessage: $t('login.Pleaseinputapassword')
                  }"
                />
                <i
                  class="iconfont" 
                  :class="!passwordShow ? 'icon-yincang show' : 'icon-xianshi'" 
                  @click="passwordShow = !passwordShow"
                ></i>
              </div>
              <span>{{ $t("login.Password") }}</span>
            </div>
            <div class="inputBox">
              <div class="felxInput">
                <input
                  v-model="ruleForm.auth"
                  v-focus
                  v-required="{
                    message: $t('login.authCode'),
                    errorMessage: $t('login.Pleaseinputaauthcode')
                  }"
                />
                <img
                  class="graphical"
                  :src="ruleForm.verificationImg"
                  @click="acquireVerification"
                />
              </div>
              <span>{{ $t("login.authCode") }}</span>
            </div>
            <div 
              class="flexAlignBetween"
              style="margin-top: 1.25rem;">
              <p class="forgot" @click="toForgot">{{ $t('login.Forgot password') }}</p>
              <p class="registerBox">
                {{ $t("login.HaveNoAccountRegister").split("？")[0] }}？
                <span @click="toRegister">
                  {{ $t("login.HaveNoAccountRegister").split("？")[1] }}
                </span>
              </p>
            </div>
            
            <div class="isSeeLawBox">
              <el-checkbox v-model="lawCheckBox" />
              <div class="text">
                {{ $t('I have read and agree with') }} 
                <a @click="goLaw(0)">{{ $t('Service agreement') }}</a> 
                {{ $t('and') }} 
                <a @click="goLaw(1)">{{ $t('Privacy policy') }}</a>
              </div>
            </div>
            <el-button
              type="primary" 
              :loading="loginLoading" 
              :disabled="submitDisabled" 
              class="buttonBox"
              @click="submitForm('ruleForm')"
            >{{ $t('login.Login') }}</el-button>
          </form>
        </div>
        <div v-show="loginTypeStatus == 'phoneLogin'">
          <h2>{{ $t('registerLoging.CellphoneLogin') }}</h2>
          <div class="inputBox">
            <div class="felxInput">
              <input
                v-model="ruleForm.phone"
                type="text"
                :maxlength="11"
                @input="() => ruleForm.phone = ruleForm.phone.replace(/\D/g, '')"
                v-focus
                v-required="{
                  message: $t('login.phoneNo'),
                  errorMessage: $t('login.Pleaseenteryourmobilenumber')
                }"
              />
            </div>
            <span>{{ $t("login.phoneNo") }}</span>
          </div>
          <div class="inputBox">
            <div class="felxInput">
              <input
                v-model="ruleForm.captcha"
                type="text"
                :maxlength="6"
                @input="() => ruleForm.captcha = ruleForm.captcha.replace(/\D/g, '')"
                v-focus
                v-required="{
                  message: $t('login.authCode'),
                  errorMessage: $t('login.Pleaseinputaauthcode')
                }"
              />
              <p class="phoneGetCode" @click="pushPhoneCode()">{{
                !countDown
                  ? countDownNum
                  : $t("registerLoging.GetVerificationCode")
              }}</p>
            </div>
            <span>{{ $t("login.authCode") }}</span>
          </div>
          <div class="isSeeLawBox">
            <el-checkbox v-model="lawCheckBox" />
            <div class="text">
              {{ $t('I have read and agree with') }} 
              <a @click="goLaw(0)">{{ $t('Service agreement') }}</a> 
              {{ $t('and') }} 
              <a @click="goLaw(1)">{{ $t('Privacy policy') }}</a>
            </div>
          </div>
          <el-button
            type="primary" 
            :loading="loginLoading" 
            :disabled="submitPhoneDisabled" 
            class="buttonBox"
            @click="submitForm('captchaForm')"
          >{{ $t('login.Login') }}</el-button>
        </div>
        <div v-show="loginTypeStatus == 'wecatLogin'">
          <h2>{{ $t('registerLoging.weChatLogin') }}</h2>
          <div class="wechatBox" v-loading="loginLoading">
            <iframe
              :src="wxAuthUrl"
              frameborder="0"
              width="100%"
              height="100%"
              id="frame"
              v-if="wxAuthUrl"
            ></iframe>
          </div>
        </div>
        <div class="dividerBox">
          <el-divider>
            <span>Or</span>
          </el-divider>
        </div>
        <div class="otherLoginBox">
          <el-button class="btn" v-if="loginTypeStatus == 'phoneLogin' || loginTypeStatus == 'wecatLogin'" @click="goLogin">
            <i class="iconfont icon-zhanghao"></i>
            {{ $t('login.AccountButtonLogin') }}
          </el-button>
          <el-button v-if="loginTypeStatus == 'login' || loginTypeStatus == 'wecatLogin'" class="btn" @click="goPhoneLogin">
            <i class="iconfont icon-shouji"></i>
            {{ $t('registerLoging.CellphoneLogin') }}
          </el-button>
          <el-button v-if="loginTypeStatus == 'phoneLogin' || loginTypeStatus == 'login'" class="btn" @click="goWecatLogin">
            <i class="iconfont icon-weixin"></i>
            {{ $t('registerLoging.weChatLogin') }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="translateButton">
      <el-tabs 
        class="special_tabs" 
        v-model="translate_type"
        @tab-click="translateChange">
        <el-tab-pane label="中" name="zh" />
        <el-tab-pane label="En" name="en" />
      </el-tabs>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="446px"
      class="noRegisterUser"
      v-move-outside
    >
      <div class="dialog_box">
        <p>{{ $t("login.The current account name does not exist") }}</p>
        <p>{{ $t("login.Go to register") }}</p>
        <div>
          <el-button @click="dialogVisible = false">{{
            $t("login.Back to Login")
          }}</el-button>
          <el-button type="info" @click="toRegister">
            {{ $t("login.Gotoregister") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getCaptcha, 
  getPublicKey, 
  getPhoneCode,
  wechatLogin
} from "@/api/index";
import Config from "@/store/Config";
import { rsaData } from "@/utils/encrypt";

export default {
  name: "Login",
  data() {
    return {
      bgIndex: 1,
      translate_type: "zh",
      dialogVisible: false,
      passwordShow: true,
      isPasswordShow: true,
      loginLoading: false,
      lawCheckBox: false,
      wxAuthUrl: "",
      loginTypeStatus: 'login',
      ruleForm: {
        username: "",
        password: "",
        auth: "",
        verificationImg: "",
        phone: "",
        captcha: ""
      },
      timer: null,
      countDown: true,
      countDownNum: 60,
      isAlive: true,
      loginBackground: [{
        bottom: '90px',
        left: '60px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%B8%80.jpg"
      }, {
        bottom: '35%',
        left: '60px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%B8%89.jpg"
      }, {
        bottom: '90px',
        left: '60px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%BA%8C.jpg"
      }, {
        bottom: '10%',
        left: '80px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%BA%94.jpg"
      }, {
        bottom: '90px',
        left: '60px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E5%85%AD.jpg"
      }, {
        bottom: '90px',
        left: '60px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E5%9B%9B.jpg"
      }, {
        bottom: '65%',
        left: '70px',
        image: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E6%97%A5.jpg"
      }]
    };
  },
  computed: {
    submitDisabled() {
      return !(this.ruleForm.username && this.ruleForm.password && this.ruleForm.auth)
    },
    submitPhoneDisabled() {
      return !(this.ruleForm.phone && this.ruleForm.captcha)
    }
  },
  watch: {
    // 监听路由变化
    $route(to, from) {
      const {
        query: { code },
      } = to;
      if (code) {
        this.$store.dispatch("user/wxLogin", { code }).catch(() => {});
      }
    },
    translate_type(newVal, oldVal) {
      if(newVal != oldVal) {
        this.translateChange();
      }
    }
  },
  mounted() {
    this.translate_type = this.$store.state.user.language;
    if(this.$route.query.username) {
      this.ruleForm.username = this.$route.query.username;
      this.$refs.username.focus();
    }
    // this.acquireVerification();
    this.getWxAuthUrl();
    this.bgIndex = Number(localStorage.getItem("bgIndex")) || 1;
    if(this.bgIndex > 6) {
      this.bgIndex = 1;
      localStorage.setItem("bgIndex", 1);
    } else {
      localStorage.setItem("bgIndex", ++this.bgIndex);
    }
    sessionStorage.clear();
  },
  activated() {
    this.acquireVerification();
    this.translate_type = this.$store.state.user.language;
    this.$i18n.locale = this.$store.state.user.language;
    this.$store.dispatch("user/setLanguage", this.$store.state.user.language);
  },
  methods: {
    setInputConfig() {
      if(!this.isPasswordShow) return;
      this.isPasswordShow = false;
      setTimeout(() => {
        this.ruleForm = {
          username: "",
          password: "",
          auth: "",
          verificationImg: "",
          phone: "",
          captcha: ""
        };
        this.acquireVerification();
      }, 10)
    },
    // 监听翻译按钮
    translateChange() {
      this.$i18n.locale = this.translate_type;
      this.$store.dispatch("user/setLanguage", this.translate_type);
      this.isAlive = false;
      this.$nextTick(function(){
        this.isAlive = true;
      })
      this.isPasswordShow = true;
      this.setInputConfig();
    },
    //获取验证码
    acquireVerification() {
      getCaptcha()
        .then((response) => {
          if (!response) return;
          this.ruleForm.verificationImg = URL.createObjectURL(response);
        })
    },
    getWxAuthUrl() {
      const { href } = window.location;
      wechatLogin({ callBackUrl: href }).then((data) => {
        this.wxAuthUrl = data;
      });
    },
    pushPhoneCode() {
      if (!this.countDown) {
        return;
      }
      getPhoneCode({ phone: this.ruleForm.phone }).then(() => {
        this.countDownFun();
      });
    },
    countDownFun() {
      this.countDown = false;
      this.countDownNum = 60;
      this.timer = setInterval(() => {
        this.countDownNum--;
        if (this.countDownNum <= 0) {
          this.countDown = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    goLaw(type) {
      this.$router.push(`/lawDetail?type=${type}`)
    },
    toForgot() {
      this.$router.push(`/forgot`)
    },
    goLawTwo(type) {
      this.$router.push(`/lawDetail?type=${type}`)
      this.$msgbox.close();
    },
    submitForm(formName) {
      if(this.loginLoading) return;
      if(!this.lawCheckBox) {
        this.$confirm(
          (
            <div class="confirmLawConetnt">
              {this.$t('InformationCenter.Agree')}<span> </span>
              <a onClick={() => this.goLawTwo(0)}>{this.$t('Service agreement')}</a><span> </span>
              {this.$t('and')}<span> </span>
              <a onClick={() => this.goLawTwo(1)}>{this.$t('Privacy policy')}</a><span> </span>
              {this.$t('And log in')}
            </div>
          ), 
          this.$t('Tips'), 
          {
            confirmButtonText: this.$t('InformationCenter.Agree')+' '+this.$t('And log in'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.lawCheckBox = true;
          this.submitForm(formName);
        }).catch(() => {});
        return;
      }
      if (formName === "captchaForm") {
        this.loginLoading = true;
        this.phoneCodeLogin();
      } else {
        if(!this.ruleForm.auth) {
          return;
        }
        this.loginLoading = true;
        this.cry_2_log();
      }
    },
    // 手机号登录
    phoneCodeLogin() {
      let forms = {
        phone: this.ruleForm.phone,
        smsCode: this.ruleForm.captcha
      }
      this.$store.dispatch("user/codeLogin", forms).finally(() => {
        setTimeout(() => {
          this.loginLoading = false;
        }, 200);
        this.clearForm();
      });
    },
    //密码加密并登录
    cry_2_log() {
      getPublicKey().then((response) => {
        if (!response) return;
        this.publickey = response;
        if (false == Config.IS_DEBUG) {
          this.cry_password = rsaData(
            this.ruleForm.password,
            this.publickey
          );
        }
        this.login();
      }).catch((error) => {});
    },
    //登录动作接口
    login() {
      var data = {
        username: this.ruleForm.username,
        password: !Config.IS_DEBUG ? this.cry_password : this.ruleForm.password,
        captcha: this.ruleForm.auth,
      };
      this.$store.dispatch("user/login", data).then(() => {
        setTimeout(() => {
          this.loginLoading = false;
        }, 200);
        this.clearForm();
        localStorage.removeItem("rsa");
        localStorage.removeItem("captcha");
      }).catch(() => {
        setTimeout(() => {
          this.loginLoading = false;
        }, 200);
        this.acquireVerification();
      });
    },
    clearForm() {
      this.ruleForm = {
        username: "",
        password: "",
        auth: "",
        verificationImg: "",
        phone: "",
        captcha: ""
      }
      document.querySelectorAll(".inputBox").forEach(item => {
        item.classList.remove("valid");
      });
    },
    goLogin() {
      this.loginTypeStatus = 'login'
    },
    goPhoneLogin() {
      this.loginTypeStatus = 'phoneLogin'
    },
    goWecatLogin() {
      this.loginTypeStatus = 'wecatLogin';
    },
    // 跳转注册页面
    toRegister() {
      this.$router.push({
        path: "/register",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./style/index.less";
</style>

<style lang="less">
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #757d8a !important;
  -webkit-background-clip: text !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.noRegisterUser {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    border-top: 0;
  }
}
.confirmLawConetnt {
  font-size: 18px;
  word-break: break-all;
  a {
    color: #157DF8;
    cursor: pointer;
  }
}
</style>
